import React, { ReactElement, useState, useEffect, useContext } from 'react'
import { navigate } from 'gatsby'
import { makeStyles, Theme, CircularProgress } from '@material-ui/core'
import clsx from 'clsx'
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos'
import { PaymentUpload } from '../../../models/payment_upload'
import { getPaymentUploads } from '../../../services/upload.service'
import PaymentUploadTable from './upload-table.component'
import { MessageContext } from '../../../contexts/message.store'


const useStyles = makeStyles((theme: Theme) => ({
  componentWrapper: {
    display: 'flex',
    width: '100%',
  },
  backArrow: {
    marginTop: '5px',
    marginRight: theme.spacing(1),
    cursor: 'pointer',
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(4),
  },
  progressHolder: {
    margin: '43px 0',
    width: '100%',
    display: 'flex',
  },
  progress: {
    margin: '0 auto',
  },
  progressInvisible: {
    visibility: 'hidden',
  },
}))

export function HistoryComponent(): ReactElement {
  const classes = useStyles()
  const [, messageDispatch] = useContext(MessageContext)
  const [isLoading, setLoading] = useState(false)
  const [uploads, setUploads] = useState<PaymentUpload[]>([])
  const getUploadsFromApi = async (): Promise<void> => {
    try {
      setLoading(true)
      setUploads(await getPaymentUploads())
    } catch (err) {
      messageDispatch({ type: 'ERROR', message: err.status })
    }
    setLoading(false)
  }
  useEffect(() => {
    getUploadsFromApi()
  }, [])

  const goBack = (): void => {
    navigate('/uploads')
  }

  return (
    <div className={classes.componentWrapper}>
      <ArrowBackIcon className={classes.backArrow} onClick={goBack} />
      {!isLoading ? (
        <PaymentUploadTable uploads={uploads} setPaymentUploads={setUploads} />
      ) : (
        <div className={classes.progressHolder}>
          <CircularProgress
            size={183.5}
            className={clsx(classes.progress, { [classes.progressInvisible]: !isLoading })}
          />
        </div>
      )}
    </div>
  )
}
