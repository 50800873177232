/* eslint-disable react/prop-types */
import React, { ReactElement, forwardRef, useState } from 'react'
import MaterialTable from 'material-table'
import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import { makeStyles } from '@material-ui/styles'
import { Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { Payment } from '../../../models/payment'
import { delPaymentUpload } from '../../../services/upload.service'
import { PaymentUpload } from '../../../models/payment_upload'

const tableIcons = {
  Add: forwardRef<SVGSVGElement>((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef<SVGSVGElement>((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef<SVGSVGElement>((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef<SVGSVGElement>((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef<SVGSVGElement>((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef<SVGSVGElement>((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef<SVGSVGElement>((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef<SVGSVGElement>((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef<SVGSVGElement>((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef<SVGSVGElement>((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef<SVGSVGElement>((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef<SVGSVGElement>((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef<SVGSVGElement>((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef<SVGSVGElement>((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef<SVGSVGElement>((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef<SVGSVGElement>((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef<SVGSVGElement>((props, ref) => <ViewColumn {...props} ref={ref} />),
}
const useStyles = makeStyles((theme) => ({
  tableSection: {
    width: '100%',
  },
}))
type TableData = {tableData: {id: number}}
type PaymentUploadProps = {
  uploads: PaymentUpload[]
  setPaymentUploads(uploads: PaymentUpload[]): void
}
export default function PaymentUploadTableComponent({ uploads, setPaymentUploads }: PaymentUploadProps): ReactElement {
  const classes = useStyles()
  const [successMessage, setSuccessMessage] = useState('')
  const [errMessage, setErrMessage] = useState('')
  return (
    <>
      {uploads && (
        <section className={classes.tableSection}>
          <MaterialTable
            icons={tableIcons}
            editable={{
              onRowDelete: (oldData: Payment&TableData): Promise<void> => new Promise((resolve, reject) => {
                const dataDelete = [...uploads]
                const index = oldData.tableData.id
                delPaymentUpload(oldData.id).then((success: number) => {
                  if (success) {
                    dataDelete.splice(index, 1)
                    setPaymentUploads([...dataDelete])
                    setSuccessMessage('Payment Deleted Successfully')
                    resolve()
                  } else reject()
                }).catch(() => {
                  setErrMessage('Deleting Payment Failed')
                  reject()
                })
              }),
            }}
            localization={{ body: { editRow: { deleteText: 'Are you sure you want to delete upload? All associated payments will be deleted.' } } }}
            columns={[
              { title: 'Id',
                field: 'id',
              },
              { title: 'Created At',
                field: 'created_at',
                type: 'date',
              },
              {
                title: 'Number of Payments',
                field: 'num_payments',
                type: 'numeric',
              },
            ]}
            options={{ pageSize: 10, actionsColumnIndex: -1, search: false }}
            data={uploads}
            title="Upload History"

          />
          <Snackbar
            open={!!successMessage}
            autoHideDuration={3000}
            onClose={(): void => {
              setSuccessMessage('')
            }}
          >
            <Alert severity="success">{successMessage}</Alert>
          </Snackbar>
          <Snackbar
            open={!!errMessage}
            autoHideDuration={3000}
            onClose={(): void => {
              setErrMessage('')
            }}
          >
            <Alert severity="error">{errMessage}</Alert>
          </Snackbar>
        </section>
      )}
    </>
  )
}
