import React, { ReactElement } from 'react'

import { AppComponent } from '../../components/app/app.component'
import HeadComponent from '../../components/head.component'
import { HistoryComponent } from '../../components/uploads/history/history.component'
import LoginGuardComponent from '../../components/app/login.gaurd.component'

export default function History(): ReactElement {
  return (
    <AppComponent>
      <LoginGuardComponent>
        <HeadComponent title="CSV Upload History" />
        <HistoryComponent />
      </LoginGuardComponent>
    </AppComponent>
  )
}
